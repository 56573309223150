  .banner {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .add-category-btn {
      background-color: #4CAF50;
      color: white;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 20px;
      font-weight: bold;

      &:hover {
          background-color: #45a047;
      }
  }

  .action-icon {
      margin: 0 8px;
      cursor: pointer;
      font-size: 1.5rem;
      color: #333;
      transition: color 0.3s;
  }

  .action-icon:hover {
      color: #007bff;
  }