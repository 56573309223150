.quesImageBefore {
   width: 100%;
   min-height: 100px;
   background-color: #d6d6d6;
}
.quesImageAfter {
   background-color: #d6d6d6;
   width: 200px;
   display: flex;
   justify-self: center;
   min-height: auto;
   border: 1px solid #cacaca;
   border-radius: 10px;
}

.quesOptionContainer {
   margin-top: 56px;
   /* margin-bottom: 60px; */
   width: 90%;
   /* min-height: 360px; */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.rowContainer {
   display: flex;
   align-items: center;
   justify-content: center;
}

.optionContainer {
   /* margin-top: 40px; */
   position: relative;
   width: 200px;
   min-height: 150px;

   &:nth-child(1),
   &:nth-child(2) {
      margin: 0;
   }

   &:not(:first-child) {
      margin-left: 48px;
   }
}

.option {
   font-size: 88px;
   border: 1px solid #d6d6d6;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 16px;
   color: #6a4a35;
}

.chooseOption {
   position: absolute;
   top: 0;
   opacity: 0;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.optionActive {
   border-radius: 32px;
   border: 3px solid #008000 !important;
}

.audioContainer {
   display: flex;
   align-items: center;
}

.audioContainer > span {
   font-size: 28px;
   font-weight: 600;
}

.speaker {
   font-size: 64px;
   color: white;
   background-color: #6a4a35;
   padding: 10px;
   border-radius: 10px;
   cursor: pointer;
}
