body {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f4f4f4; 
}

.banner {

    margin-left: 10px;

  }

  h1 {
    color: #333; 
  }
  
  .add-category-page {
    max-width: 500px;
    margin: 100px auto; 
    background-color: #fff; 
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); 
  }
  
  .add-category-page label {
    display: block; 
    margin-bottom: 10px; 
    font-weight: bold;
    padding-bottom: 5px; 
  }
  
  .add-category-page input[type="text"]{
    width: 170px;
    height: 25px;
    margin: 5px;
    border: 1px solid #ccc; 
    border-radius: 4px;
  }

  .add-category-page select {
    width: 170px;
    height: 25px;
    margin: 5px;
    padding: px; 
    margin-top: 10px; 
    border: 1px solid #ccc; 
    border-radius: 4px;
  }
  
  .add-category-page button {
    padding: 10px;
    background-color: #28a745; 
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-category-page button:hover {
    background-color: #218838; 
  }