.s5optionContainer {
   min-height: 750px;
   width: 100%;
   margin: 16px 0;

   display: grid;
   /* grid-template-rows: repeat(5, 1fr);
   gap: 10px;
   grid-auto-columns: minmax(100px, auto); */
}

.s5option {
   display: flex;
   justify-content: center;
   align-items: center;
}

.s5option > div {
   height: 110px;
   width: 110px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.selected {
   border: 4px solid green;
   border-radius: 16px;
}
